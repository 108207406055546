import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image";

const StyledAuthor = styled.div`
margin-right:1rem;
  a {
    color: #31d1ff;
    font-size: 0.8rem;
  }
  h5 {
    margin: 0.2rem;
  }
`


const AuthorInfo = ({ author }) => {
  return (
    <StyledAuthor>
      <Img fixed={author.image.childImageSharp.fixed}/>
      <h5>{author.detail}</h5>
      {/* <a href="https://twitter.com/ilias_dev">{author.twitter}</a> */}
    </StyledAuthor>
  )
}
export default AuthorInfo
