import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { UnderLine, ButtonLink } from '../components/global-style';
import AuthorInfo from '../components/author-info';
import Arrow from '../img/svg/arrow-left.svg';

const BlogContainer = styled.section`
  .hero-content {
    color: white;
    padding: 3rem 1rem;
    max-width: 800px;
    margin: 0 auto;
    height:400px;
    max-height: 450px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ul {
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
    ul li {
      list-style: none;
      margin-right: 1rem;
    }
  }
  .content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
`;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  readingTime,
  image,
  author,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <BlogContainer>
      {helmet || ''}
      <div className="hero">
        <BackgroundImage fluid={image.childImageSharp.fluid}>
          <div className="hero-content">
            <h1> {title}</h1>
            <UnderLine />
            {tags && tags.length ? (
              <ul className="taglist">
                {tags.map(tag => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            ) : null}
            <span>Temps de lecture: {Math.floor(readingTime)} minutes</span>
          </div>
        </BackgroundImage>
      </div>
      <div className="content">
        <div>
          <AuthorInfo author={author} />
        </div>
        <p>{description}</p>
        <PostContent content={content} />

        <ButtonLink to="/blog">
          {' '}
          <img src={Arrow} />
        </ButtonLink>

        {tags && tags.length ? (
          <div style={{ marginTop: `4rem` }}>
            <h4>Tags</h4>
            <ul className="taglist">
              {tags.map(tag => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </BlogContainer>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        image={post.frontmatter.Image}
        date={post.frontmatter.date}
        readingTime={post.fields.readingTime.minutes}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        author={post.frontmatter.author}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{post.frontmatter.title}</title>
            <meta name="description" content={post.frontmatter.description} />
            <meta property="og:title" content={post.frontmatter.title} data-react-helmet="true" />
            <meta
              property="og:image"
              content={post.frontmatter.Image.childImageSharp.fluid.src}
              data-react-helmet="true"
            />
            <meta
              property="og:image"
              content={post.frontmatter.description}
              data-react-helmet="true"
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY",locale:"fr")
        title
        description
        tags
        author {
          detail
          image {
            childImageSharp {
              fixed(width: 125, height: 125) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        Image {
          childImageSharp {
            fluid(maxWidth: 1048,maxHeight:600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          minutes
        }
      }
    }
  }
`;
